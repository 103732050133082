import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';
import Cookies from 'js-cookie';
import { Context } from 'store/createContext';
import MEDIA from 'helpers/mediaTemplates';
import Layout from 'components/layout';
import Box from 'components/box';
import WorksheetDownload from 'components/worksheet-download';
import SalesScriptLink from 'components/sales-script-link';
import Title from 'components/title';
import Banner from 'components/banner';
import Ad from 'components/ad';
import {
  almostBlack,
  lightGrey,
  brNorm,
  rhythm,
  purple,
} from 'constants/theme';

const Button = ({ children, onClick }) => {
  return (
    <button className="add-to-calendar-button" onClick={onClick}>
      {children}
    </button>
  );
};

const Dropdown = ({ children }) => {
  return <div className="add-to-calendar-dropdown">{children}</div>;
};

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ResiReplayInfo = styled.div`
  padding: ${rhythm};
  border-radius: 0 0 ${brNorm} ${brNorm};
  background-color: ${almostBlack};
  color: ${lightGrey};
  text-align: center;
  line-height: 1.4;

  a {
    color: white;
    text-decoration: underline;
  }

  strong {
    color: white;
  }
`;

const StyledResiContainer = styled.div`
  width: 100%;
  margin: 4rem 0 6rem;
  padding: 0 4rem;

  .resi--container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    border-radius: ${brNorm} ${brNorm} 0 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const StyledAdsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: auto;
  margin-top: 1.25rem;
  margin: 1.25rem 2rem 0 2rem;

  ${MEDIA.DESKTOP`
    grid-template-columns: 100%;
  `};

  ${MEDIA.TABLET`
    margin: 2rem 0rem;
  `};
`;

const HeroText = styled.span`
  color: ${purple};
`;

const TagLine = styled.span`
  margin: 0.5rem;
  font-size: 18px;
  font-weight: 500;
`;

const Index = ({ data, location }) => {
  //
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  dayjs.extend(dayjsPluginUTC);
  const { events } = data.allEventsJson.nodes[0];

  // Component State
  const [theEvent, setTheEvent] = useState(events[0]);
  const [toggleOn, setToggleOn] = useState(0);
  const [bannerMessage, setBannerMessage] = useState('');
  const store = useContext(Context);
  const { eventTiming, setGlobalEventTiming } = store;

  useEffect(() => {
    (async function init() {
      await checkFeatureToggles();
      getRsvp();
      websocketConnect();
      handleCookies();
      loadZendesk();
    })();
  }, []);

  useEffect(() => {
    checkEventStatus(toggleOn);
    // check for event status every minute
    const interval = setInterval(() => {
      checkEventStatus(toggleOn);
    }, 30000);
    return () => clearInterval(interval);
  }, [toggleOn]);

  const getRsvp = () => {
    const params = new URLSearchParams(location.search);
    const rsvpParam = params.get('rsvp');

    store.setRsvp(rsvpParam);
  };

  const websocketConnect = () => {
    const heartbeat = ws => {
      ws.send('__beat__');
    };

    let ws;
    const connect = () => {
      let interval;
      ws = new WebSocket('wss://prod.mastery.businessmadesimple.com/ws');

      // Setup our heartbeat to keep connection open
      ws.onopen = () => {
        console.log('Socket connected.');
        interval = setInterval(() => {
          heartbeat(ws);
        }, 30000);
      };

      // If heartbeat, ignore
      // Otherwise, update our store
      ws.onmessage = msg => {
        if (msg.data === '__alive__') return;
        const { data, event } = JSON.parse(msg.data);

        if (event.includes('ToggleFeatureEvent')) {
          const toggle = data.toggles.find(t => t.feature === 'force_tcith');
          setToggleOn(toggle.on);

          const banner = data.toggles.find(
            t => t.feature === 'workshop_banner'
          );
          if (banner.on) {
            setBannerMessage(banner.additional);
          } else {
            setBannerMessage('');
          }
        }
      };

      // Clear our heartbeat if websocket closes unexpectedly
      ws.onclose = () => {
        ws = null;
        clearInterval(interval);
        console.log('Socket connection has closed.');
      };

      ws.onerror = error => {
        console.log('Socket error: ', { error });
      };
    };

    const reconnect = async () => {
      try {
        await connect();
      } catch (error) {
        console.log('Error reconnecting to websocket: Error ', { error });
      }
    };

    // Initial socket connection
    try {
      connect();
    } catch (error) {
      console.log('Socket error connecting.');
    }

    // Reconnect to socket if we lose connection
    setInterval(() => {
      if (!ws) {
        reconnect();
      }
    }, 5000);
  };

  const handleCookies = () => {
    let ref;
    const query = window.location.search.substring(1);
    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == 'ref') {
        ref = decodeURIComponent(pair[1]);
      }
    }

    if (ref && ref !== undefined && ref !== 'undefined') {
      Cookies.set('tcith-livestream-ref', ref, {
        expires: 90,
        domain: 'thecustomeristhehero.com',
      });
    }
  };

  const loadZendesk = () => {
    let zdscript = document.createElement('script');
    zdscript.setAttribute('id', 'ze-snippet');
    zdscript.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.GATSBY_ZENDESK_KEY}`;
    document.getElementsByTagName('body')[0].appendChild(zdscript);

    if (window) {
      var waitForZopim = setInterval(function() {
        if (
          window.$zopim === undefined ||
          window.$zopim.livechat === undefined
        ) {
          return;
        }
        window.$zopim.livechat.badge.hide();
        clearInterval(waitForZopim);
      }, 100);
    }
  };

  const checkFeatureToggles = () => {
    return axios
      .get(process.env.GATSBY_MASTERY_API)
      .then(function(response) {
        // handle success
        if (response.data) {
          const toggle = response.data.find(f => f.feature === 'force_tcith');
          setToggleOn(toggle.on);

          const banner = response.data.find(t => t.feature === 'tcith_banner');

          if (banner.on) {
            setBannerMessage(banner.additional);
          } else {
            setBannerMessage('');
          }
        }
      })
      .catch(function(error) {
        // handle error
        // console.log(error);
      });
  };

  const checkEventStatus = async FEATURE_TOGGLE => {
    // Get event to display
    const now = dayjs.utc();

    // const toggleOn = await checkFeatureToggles();

    // if right now is within an hour of any event's start time
    const currentlyHappening = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc);
      const end = dayjs.utc(event.end_date_utc);
      const eventLength = start.diff(end, 'minute');
      const diff = start.diff(now, 'minute');
      return diff <= 30 && diff >= eventLength - 14;
    });

    // if right now is within 1  hours of one of the event's end time
    const recentlyEndedEvents = events.filter(event => {
      const end = dayjs.utc(event.end_date_utc);
      const diff = end.diff(now, 'minute');
      return diff < -14 && diff > -60;
    });

    // any events in the future
    const upcomingEvents = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc);
      const diff = start.diff(now, 'minute');
      return diff > 0;
    });

    if (FEATURE_TOGGLE) {
      if (eventTiming !== 'currently') {
        setGlobalEventTiming('currently');
        setTheEvent(upcomingEvents[0]);
      }
      return;
    }

    if (
      upcomingEvents.length &&
      !currentlyHappening.length &&
      !recentlyEndedEvents.length
    ) {
      if (eventTiming !== 'upcoming') {
        setGlobalEventTiming('upcoming');
        setTheEvent(upcomingEvents[0]);
      }
    }

    if (currentlyHappening.length) {
      if (eventTiming !== 'currently') {
        setGlobalEventTiming('currently');
        setTheEvent(currentlyHappening[0]);
      }
    }

    if (recentlyEndedEvents.length) {
      if (eventTiming !== 'over') {
        setGlobalEventTiming('over');
        setTheEvent(recentlyEndedEvents[0]);
      }
    }
  };

  const StartDateInCentralTime = dayjs(theEvent.start_date_central);
  const EndDateInCentralTime = dayjs(theEvent.end_date_central);

  const AMorPM = date => {
    let amOrPm = null;
    try {
      date ? (amOrPm = date.format('a') === 'am' ? 'AM' : 'PM') : null;
    } catch (err) {
      //
    }
    return amOrPm;
  };

  // Choosing the ad
  const { ads } = data.allAdsJson.nodes[0];

  // // TODO get more ads and add logic
  // const ad = ads[0];

  // Calendar Event
  const calendarEvent = event => {
    let rsvp = store ? store.rsvp : '';
    return {
      description: event.description,
      duration: 6.5,
      endDatetime: dayjs(theEvent.end_date_central).format('YYYYMMDDTHHmmss'),
      location: 'https://workshop.thecustomeristhehero.com/?rsvp=' + rsvp,
      startDatetime: dayjs(theEvent.start_date_central).format(
        'YYYYMMDDTHHmmss'
      ),
      title: 'The Customer is the Hero Live Sales Workshop',
      timezone: 'America/Chicago',
    };
  };

  return (
    <Layout bannerMessage={bannerMessage}>
      <Box>
        <Title as="h2" size="large">
          The Customer is the <HeroText>Hero</HeroText>
        </Title>
        <TagLine>A Sales Framework for People Who Hate Selling</TagLine>
        {store.verifiedRsvp === 'verified' ? (
          <FlexContainer>
            <WorksheetDownload href={theEvent.workbook_download}>
              <Img
                fluid={data.imageSharp ? data.imageSharp.fluid : {}}
                alt={'Workbook download'}
              />
              Workbook
            </WorksheetDownload>
            <SalesScriptLink href="https://app.onlinesalesscript.com/login">
              <Img
                fluid={data.salesScript ? data.salesScript.fluid : {}}
                alt={'Online Sales Script'}
              />
              Online SalesScript
            </SalesScriptLink>
          </FlexContainer>
        ) : null}
        {eventTiming === 'currently' ? (
          store.verifiedRsvp !== 'verified' ? null : (
            <>
              <StyledResiContainer>
                <div className="resi--container">
                  <iframe
                    title="The Customer is the Hero Live Sales Workshop"
                    allow="autoplay; fullscreen"
                    allowFullScreen={true}
                    src={`https://control.resi.io/webplayer/video.html?id=${theEvent.embed_code}&iframe=1&autoplay=1`}
                    style={{ width: '100%' }}
                  ></iframe>
                </div>
                <ResiReplayInfo className="resi--replay-info">
                  You will have access to the replay of the livestream (not
                  coaching breakout sessions) for two full weeks afterward. You
                  will receive an email to the address you used for
                  registration. Your email will arrive the afternoon of{' '}
                  {StartDateInCentralTime.add('6', 'day').format('dddd MMMM D')}
                  . Have questions about the stream? Get answers{' '}
                  <a
                    href={
                      'https://thecustomeristhehero.com/live-sales-workshop-faqs/'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>HERE.</strong>
                  </a>
                </ResiReplayInfo>
              </StyledResiContainer>
            </>
          )
        ) : (
          <Banner>
            <BackgroundImage
              Tag="div"
              fluid={theEvent.banner_image.source.childImageSharp.fluid}
              className="banner-background"
              style={{
                backgroundPosition: 'center center',
                width: '100%',
                borderRadius: '3rem',
                overflow: 'hidden',
              }}
            >
              <div className="banner-content">
                {/* <h3 className="banner-content__title">
                  {data.bannerJson.title}
                </h3> */}
                {eventTiming === 'upcoming' && (
                  <p className="banner-content__about">
                    The Livestream will start on this page on:
                  </p>
                )}
                {eventTiming === 'upcoming' ? (
                  <>
                    <p className="banner-content__start-date">
                      {StartDateInCentralTime.format('dddd MMMM D, YYYY')}
                    </p>
                    <p className="banner-content__start-time">
                      {StartDateInCentralTime.format('h:mm')}
                      {AMorPM(StartDateInCentralTime)} CST - Join Your Coaching
                      Room
                      {'\n'}
                      {StartDateInCentralTime.add(30, 'minute').format('h:mm')}
                      {AMorPM(StartDateInCentralTime)} CST - Morning Session
                      Begins
                      {'\n'}
                    </p>
                  </>
                ) : (
                  <div className="banner-content__over">This Event is Over</div>
                )}
                {eventTiming === 'upcoming' &&
                store.verifiedRsvp === 'verified' ? (
                  <AddToCalendarDropdown
                    className="add-to-calendar"
                    buttonText="Add to my calendar"
                    event={calendarEvent(theEvent)}
                    items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                  />
                ) : null}
              </div>
              {eventTiming === 'upcoming' ? (
                <ResiReplayInfo className="resi--replay-info">
                  You will have access to the replay of the livestream (not
                  coaching breakout sessions) for two full weeks afterward. You
                  will receive an email to the address you used for
                  registration. Your email will arrive the afternoon of{' '}
                  {StartDateInCentralTime.add('6', 'day').format('dddd MMMM D')}
                  . Have questions about the stream? Get answers{' '}
                  <a
                    href={
                      'https://thecustomeristhehero.com/live-sales-workshop-faqs/'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>HERE.</strong>
                  </a>
                </ResiReplayInfo>
              ) : null}
            </BackgroundImage>
          </Banner>
        )}

        <StyledAdsContainer>
          {ads.map(ad => (
            <Ad ad={ad} key={ad.title} />
          ))}
        </StyledAdsContainer>

        {/* <IOExample /> */}

        {/* <Modal>
        <video
          src="https://i.imgur.com/gzFqNSW.mp4"
          playsInline
          loop
          autoPlay
          muted
        />
      </Modal> */}
      </Box>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
    bannerJson {
      title
      image {
        alt
        source {
          childImageSharp {
            fluid(maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allEventsJson {
      nodes {
        events {
          additional
          cta
          description
          embed_code
          start_date_utc
          end_date_utc
          start_date_central
          end_date_central
          id
          name
          workbook_download
          banner_image {
            alt
            source {
              childImageSharp {
                fluid(maxHeight: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        id
      }
    }
    allAdsJson {
      nodes {
        ads {
          copy
          cta
          cta_link
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    }
    imageSharp(original: { src: { regex: "/Worksheet/" } }) {
      gatsbyImageData
      fluid(maxWidth: 30) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    salesScript: imageSharp(original: { src: { regex: "/Sales-Script/" } }) {
      gatsbyImageData
      fluid(maxWidth: 50) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
