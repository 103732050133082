import React from 'react';
import PropTypes from 'prop-types';
import { SalesScriptLink } from './sales-script-link.css';

const _SalesScriptLink = ({ children, href }) => (
  <SalesScriptLink href={href} target="_blank">
    {children}
  </SalesScriptLink>
);

_SalesScriptLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default _SalesScriptLink;
